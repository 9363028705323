import { httpGet, httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取部门列表
export function GET_DEPT_LIST(params: object) {
    return httpGet("/system/dept/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取部门详情
export function GET_DEPT_DETAIL(params: object) {
    return httpPost("/system/dept/detail", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 新增部门
export function ADD_DEPT(params: object) {
    return httpPost("/system/dept/add", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 编辑部门
export function EDIT_DEPT(params: object) {
    return httpPost("/system/dept/edit", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 删除部门
export function DELETE_DEPT(params: object) {
    return httpPost("/system/dept/delete", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
