import { httpGet, httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取用户列表
export function GET_USER_LIST(params: object) {
    return httpPost("/system/user/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取用户详情
export function GET_USER_DETAIL(params: object) {
    return httpPost("/system/user/detail", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 新增用户
export function ADD_USER(params: object) {
    return httpPost("/system/user/add", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 编辑用户
export function EDIT_USER(params: object) {
    return httpPost("/system/user/edit", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 删除用户
export function DELETE_USER(params: object) {
    return httpPost("/system/user/delete", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
